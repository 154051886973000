<template>
    <div class="home">
        shouye 
    </div>
</template>

<script>
    import {
        test301
    } from '@/api/api'
    import {
        testData,
        getProjectListApi
    } from '@/api/test'
    import axios from 'axios'

    export default {
        name: 'Home',
        methods: {
            
        }
    }
</script>